@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
@font-face {
  font-family: "untitled-font-2";
  src: url("../fonts/untitled-font-2.eot");
  src: url("../fonts/untitled-font-2.eot?#iefix") format("embedded-opentype"), url("../fonts/untitled-font-2.woff") format("woff"), url("../fonts/untitled-font-2.ttf") format("truetype"), url("../fonts/untitled-font-2.svg#untitled-font-2") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "untitled-font-2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-call-old-telephone:before {
  content: "\61"; }

.icon-basket:before {
  content: "\62"; }

.icon-shopping-cart:before {
  content: "\63"; }

.icon-vallet:before {
  content: "\64"; }

.icon-wallet-money:before {
  content: "\65"; }

/*
* Custom
*/
body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  position: relative;
  min-width: 500px;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  color: #000;
  background: #fff; }

a {
  color: #c66a4f; }
  a:hover {
    text-decoration: none; }

input.form-submit {
  padding: 5px 7px;
  color: #fff;
  border: none;
  background: #c66a4f; }

h1 {
  font-size: 1.7em;
  text-align: center;
  color: #c66a4f; }

h2 {
  font-size: 1.5em;
  color: #c66a4f; }

.l-header, .l-content, .l-footer, .l-bottom {
  overflow: hidden;
  width: 94%;
  margin: 0 auto; }

.l-header {
  position: relative;
  clear: both;
  overflow: visible;
  margin: 0 auto 90px auto; }
  @media (max-width: 1170px) {
    .l-header {
      margin: 0 auto; } }

.l-slider,
.block-nivo-slider {
  font-size: 1.5em;
  clear: both;
  width: 100%;
  max-width: 800px;
  max-height: 400px;
  margin: 0 auto; }
  .l-slider .nivo-caption,
  .block-nivo-slider .nivo-caption {
    width: 90%;
    margin: 10px 5% 25px 5%;
    padding: 25px 20px;
    opacity: .8;
    background: #666; }

.l-content {
  margin: 30px auto; }
  .l-content .l-sidebar {
    float: left;
    width: 29%;
    min-width: 300px;
    margin-right: 1%; }
    @media (max-width: 1200px) {
      .l-content .l-sidebar {
        margin-right: 0; } }
    @media (max-width: 960px) {
      .l-content .l-sidebar {
        width: 100%;
        margin-right: 0; } }
  .l-content .l-contentArea {
    float: left;
    width: 70%; }
    @media (max-width: 1100px) {
      .l-content .l-contentArea {
        width: 550px;
        margin-left: 30px; } }
    @media (max-width: 960px) {
      .l-content .l-contentArea {
        width: 100%; } }
    .l-content .l-contentArea.nosidebar {
      width: 100%;
      margin-left: 0; }

.l-footer {
  padding-top: 50px;
  color: #666;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6; }
  .l-footer .logo {
    font-size: 1.8em;
    line-height: 1.5em; }
    @media (max-width: 1350px) {
      .l-footer .logo {
        font-size: 1.6em;
        line-height: 1em; } }
    @media (max-width: 1000px) {
      .l-footer .logo {
        font-size: 1.5em;
        line-height: 1em; } }
  .l-footer i {
    font-size: 16px;
    margin-right: 10px; }

.l-bottom {
  padding: 10px 0; }
  .l-bottom img {
    float: left;
    margin: 0 5px; }
  .l-bottom p {
    float: left;
    padding: 0;
    font-size: 1.1em;
    margin: 6px 10px 0 0;
    color: #c66a4f; }

.headerTop {
  font-size: 1em;
  line-height: 30px;
  overflow: hidden;
  color: #666;
  border-bottom: 1px solid #e6e6e6; }
  .headerTop .headerTop-left {
    font-size: 1.3em;
    position: relative;
    float: left;
    min-width: 174px;
    padding: 10px 0; }
    .headerTop .headerTop-left small {
      font-size: .6em;
      position: absolute;
      top: 28px;
      left: 21px;
      color: #999; }
  .headerTop .headerTop-right {
    float: right;
    padding: 10px 0;
    color: #c66a4f; }
    .headerTop .headerTop-right .userName a {
      position: relative;
      display: block;
      overflow: hidden;
      max-width: 100px;
      text-overflow: ellipsis;
      word-wrap: nowrap; }
    .headerTop .headerTop-right a {
      text-decoration: none; }
      .headerTop .headerTop-right a:hover {
        text-decoration: underline; }
  .headerTop i {
    font-size: 1.5em;
    display: block;
    float: left;
    margin: 0 3px 0 3px; }
  .headerTop p {
    float: left;
    margin: 0 4px;
    padding: 0; }
  @media (max-width: 880px) {
    .headerTop .headerTop-right p {
      display: none; }
    .headerTop .headerTop-right i {
      font-size: 2em;
      margin-right: .5em;
      color: #c66a4f; } }
  @media (max-width: 750px) {
    .headerTop .headerTop-left {
      float: none;
      clear: both;
      overflow: hidden;
      width: 200px;
      margin: 0 auto; }
    .headerTop .headerTop-right {
      float: none;
      clear: both;
      width: 460px;
      margin: 0;
      padding-top: 50px; }
      .headerTop .headerTop-right p {
        display: inline; }
      .headerTop .headerTop-right i {
        font-size: 1.5em;
        margin-right: 0; } }

.callback {
  display: block;
  float: left;
  overflow: hidden;
  margin: 5px 0 0 20px;
  padding: 4px 8px;
  color: #fff;
  background: #c66a4f; }
  @media (max-width: 700px) {
    .callback {
      float: none;
      clear: both;
      overflow: hidden;
      width: 232px;
      margin: 10px auto 0 auto; } }
  .callback a {
    text-decoration: none;
    color: #fff; }
    .callback a:hover {
      text-decoration: underline; }

.searchBlock {
  position: absolute;
  top: 12px;
  left: 470px;
  margin-left: 10px; }
  .searchBlock input {
    width: 200px;
    padding: 3px 30px 3px 6px;
    border: 1px solid #666; }
  .searchBlock i {
    position: absolute;
    top: 1px;
    right: 5px;
    color: #000; }
  .searchBlock .searchSubmit {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 30px;
    height: 27px;
    margin: 0;
    padding: 0;
    border: 0;
    background: none; }
  @media (max-width: 1200px) {
    .searchBlock {
      left: 455px;
      margin: 0; }
      .searchBlock input {
        width: 180px; } }
  @media (max-width: 1130px) {
    .searchBlock {
      left: 470px; }
      .searchBlock input {
        width: 100px; } }
  @media (max-width: 700px) {
    .searchBlock {
      top: 110px;
      left: 5%;
      width: 90%; }
      .searchBlock input {
        width: 90%;
        padding: 3px 5% 3px 5%; } }

.headerMenu {
  position: relative;
  padding: 35px 0; }
  @media (max-width: 1170px) {
    .headerMenu {
      padding: 35px 0 0 0; } }

.logo {
  font-size: 2em;
  font-weight: bold;
  position: relative;
  z-index: 2;
  float: left;
  padding-left: 65px;
  text-decoration: none;
  text-transform: uppercase;
  color: #c66a4f; }
  .logo img {
    position: absolute;
    z-index: 1;
    top: -18px;
    left: -5px;
    width: 86px;
    height: 60px; }
  .logo .site-descr {
    font-size: 0.40em;
    font-weight: normal;
    width: 270px;
    margin: 12px 0 0 -40px;
    padding: 0;
    text-align: center;
    text-transform: none;
    color: #000; }

.mainMenu {
  display: block;
  float: left;
  overflow: hidden;
  margin: 0 0 0 4%;
  padding: 0;
  list-style: none; }
  .mainMenu li {
    float: left; }
    .mainMenu li a {
      font-size: 1.1em;
      font-weight: bold;
      line-height: 65px;
      display: block;
      float: left;
      margin: 0 10px;
      text-decoration: none;
      text-transform: uppercase;
      color: #666; }
      .mainMenu li a:hover, .mainMenu li a.is-active {
        color: #c66a4f; }
  @media (max-width: 1370px) {
    .mainMenu {
      margin: 0 0 0 1%; }
      .mainMenu li a {
        font-size: 1em; } }
  @media (max-width: 1270px) {
    .mainMenu li a {
      font-size: .9em;
      margin: 0 5px; } }
  @media (max-width: 1230px) {
    .mainMenu {
      clear: both;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      text-align: center;
      border-top: 1px solid #666; }
      .mainMenu li a {
        font-size: 1em;
        line-height: 50px; } }

.cart {
  line-height: 35px;
  position: relative;
  float: right;
  margin: -20px 3% 0 1%; }
  .cart a {
    text-decoration: none; }
    .cart a:hover {
      opacity: .7; }
  .cart i {
    font-size: 55px;
    margin-left: 18px;
    color: #666; }
  .cart .cart-label {
    line-height: 25px;
    position: absolute;
    top: -5px;
    right: 18px;
    display: block;
    width: 25px;
    height: 25px;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    background: #bd4131; }
  .cart .cart-description {
    line-height: 1;
    clear: both;
    text-align: center; }
  @media (max-width: 1400px) {
    .cart {
      margin: 0 0 0 1%; }
      .cart i {
        font-size: 40px; }
      .cart .cart-label {
        right: 28px; } }
  @media (max-width: 1300px) {
    .cart {
      position: absolute;
      top: 30px;
      right: 5%; } }

.ajax-shopping-cart-wrapper {
  position: absolute;
  z-index: 10;
  top: 115px;
  right: 30px;
  display: none;
  width: 400px;
  padding: 5px;
  border: 1px solid #c66a4f;
  background: #fff; }
  .ajax-shopping-cart-wrapper .ajax-shopping-cart-table th {
    font-size: .7em;
    font-weight: normal;
    text-align: center; }
  .ajax-shopping-cart-wrapper .ajax-shopping-cart-table td {
    padding: 2px;
    text-align: center; }
    .ajax-shopping-cart-wrapper .ajax-shopping-cart-table td.name {
      text-align: left; }
    .ajax-shopping-cart-wrapper .ajax-shopping-cart-table td.remove-from-cart {
      font-size: .8em; }
  .ajax-shopping-cart-wrapper .ajax-shopping-cart-more-info {
    font-size: 1.1em;
    padding-top: 15px;
    color: #c66a4f; }

.catalogFront {
  overflow: hidden;
  width: 1050px;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 1090px) {
    .catalogFront {
      width: 654px; } }
  @media (max-width: 654px) {
    .catalogFront {
      width: 436px; } }
  .catalogFront .catalogFront-item {
    position: relative;
    float: left;
    margin: 4px;
    border: 1px solid #EDE6DD;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
    -moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
    box-shadow: 0px 1px 6px 0px #C3B8AB; }
    .catalogFront .catalogFront-item p {
      font-size: 1.2em;
      position: absolute;
      top: 147px;
      left: 0;
      width: 200px;
      height: 30px;
      padding-top: 7px;
      text-align: center;
      text-transform: uppercase;
      opacity: .8;
      color: #fff;
      background: #666; }
    .catalogFront .catalogFront-item:hover {
      opacity: .8;
      background: #000; }
    .catalogFront .catalogFront-item:nth-child(9) p,
    .catalogFront .catalogFront-item:nth-child(12) p,
    .catalogFront .catalogFront-item:nth-child(15) p {
      top: 125px;
      height: 53px; }

.features {
  overflow: hidden;
  width: auto;
  margin: 20px auto; }
  .features .features-inner {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    border-radius: 10px;
    background: #c66a4f; }
    .features .features-inner .features-inner-feature {
      float: left;
      width: 249px;
      height: 65px;
      padding: 0 0 0 50px;
      text-decoration: none;
      color: #fff;
      border-right: 1px solid white; }
      .features .features-inner .features-inner-feature:last-child {
        border-right: 0; }
      .features .features-inner .features-inner-feature:hover, .features .features-inner .features-inner-feature.is-active {
        background: #bd4131; }
      .features .features-inner .features-inner-feature i {
        font-size: 3.7em;
        display: block;
        float: left;
        margin: -5px 20px 0 0; }
        .features .features-inner .features-inner-feature i.icon-wallet-money {
          font-size: 3.4em;
          margin: 7px 20px 0 0; }
      .features .features-inner .features-inner-feature p {
        font-size: 1.3em;
        margin: 9px;
        padding: 0;
        text-align: center; }
    @media (max-width: 1300px) {
      .features .features-inner {
        width: 600px; }
        .features .features-inner .features-inner-feature:nth-child(2),
        .features .features-inner .features-inner-feature:nth-child(4) {
          border-right: none; } }
    @media (max-width: 700px) {
      .features .features-inner {
        width: 300px; }
        .features .features-inner .features-inner-feature {
          border-right: none;
          border-bottom: 1px solid white; }
          .features .features-inner .features-inner-feature:last-child {
            border-bottom: none; } }

#breadcrumb a {
  font-size: .9em;
  text-decoration: none;
  color: #666; }
  #breadcrumb a:hover {
    text-decoration: underline;
    color: #000; }

/* Блок "показать по", "сортировать по" */
#block-block-5 {
  clear: both;
  overflow: hidden;
  width: 100%; }
  #block-block-5 p {
    float: left;
    margin-right: 10px; }
    #block-block-5 p:nth-child(2) {
      margin-right: 35px; }
  #block-block-5 input.form-submit {
    float: left;
    margin: 10px 0 0 10px; }

/* Блоки меню каталога */
#block-menu-menu-catalog,
#block-multiblock-1 {
  position: relative;
  width: 300px;
  border: 0; }
  #block-menu-menu-catalog h2,
  #block-multiblock-1 h2 {
    font-size: 1.3em;
    margin-top: 0;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background: #c66a4f; }
    #block-menu-menu-catalog h2:after,
    #block-multiblock-1 h2:after {
      content: " >>"; }
  #block-menu-menu-catalog .content,
  #block-multiblock-1 .content {
    position: absolute;
    z-index: 3;
    top: 44px;
    left: 0px;
    width: 300px;
    height: auto;
    padding: 0;
    border: 1px solid #c66a4f;
    background: #fff; }
    #block-menu-menu-catalog .content ul,
    #block-multiblock-1 .content ul {
      margin: 0;
      padding: 0; }
      #block-menu-menu-catalog .content ul li,
      #block-multiblock-1 .content ul li {
        margin: 0;
        padding: 0;
        list-style: none; }
        #block-menu-menu-catalog .content ul li a,
        #block-multiblock-1 .content ul li a {
          font-size: 1.1em;
          line-height: 44px;
          display: block;
          text-align: center;
          text-decoration: none;
          border-bottom: 1px solid #666; }
          #block-menu-menu-catalog .content ul li a:hover, #block-menu-menu-catalog .content ul li a.active,
          #block-multiblock-1 .content ul li a:hover,
          #block-multiblock-1 .content ul li a.active {
            color: #fff;
            background: #c66a4f; }
        #block-menu-menu-catalog .content ul li:last-child a,
        #block-multiblock-1 .content ul li:last-child a {
          border-bottom: none; }

.region-sidebar {
  /* Фильтры */ }
  .region-sidebar .block {
    width: 300px;
    margin: 0 auto 20px auto;
    border: 1px solid #c66a4f; }
    @media (max-width: 960px) {
      .region-sidebar .block {
        width: 96%; } }
    .region-sidebar .block h2 {
      font-size: 1.3em;
      margin-top: 0;
      padding: 10px 0;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      background: #c66a4f; }
    .region-sidebar .block .content {
      padding: 5px 15px; }
  .region-sidebar .views-exposed-widgets .views-exposed-widget {
    width: 100%;
    margin-bottom: 20px; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget label {
      font-size: 1em;
      font-weight: normal;
      margin-bottom: 10px;
      text-transform: uppercase;
      color: #c66a4f; }
      .region-sidebar .views-exposed-widgets .views-exposed-widget label.option {
        margin-bottom: 0;
        text-transform: none;
        color: #000; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget select {
      min-width: 200px;
      padding: 5px;
      border: 1px solid #666;
      background: none; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget .views-widget {
      clear: both;
      width: 100%; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget.slider-filter-processed .views-widget div.form-type-textfield:nth-child(1) input {
      float: left;
      width: 50px; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget.slider-filter-processed .views-widget div.form-type-textfield:nth-child(2) label {
      display: none; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget.slider-filter-processed .views-widget div.form-type-textfield:nth-child(2) input {
      float: left;
      width: 50px;
      margin-left: 144px; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget .ui-slider {
      width: 250px;
      margin-top: 45px; }
    .region-sidebar .views-exposed-widgets .views-exposed-widget.views-submit-button, .region-sidebar .views-exposed-widgets .views-exposed-widget.views-reset-button {
      width: auto;
      margin-bottom: 0; }
  @media (max-width: 960px) {
    .region-sidebar #block-views-recently-read-block {
      display: none; } }
  .region-sidebar .view-recently-read {
    overflow: hidden; }
    .region-sidebar .view-recently-read .views-row {
      float: left;
      overflow: hidden;
      width: 100px;
      height: 140px;
      margin-bottom: 20px;
      list-style: none;
      text-align: center; }
      .region-sidebar .view-recently-read .views-row .views-field-title {
        overflow: hidden;
        height: 39px; }

.views-field-field-oldprice {
  text-decoration: line-through;
  color: #666; }

.l-contentArea .block-mefibs .views-exposed-form .views-exposed-widget {
  padding: 0; }

.l-contentArea .block-mefibs .views-widget-sort-by {
  margin-right: 1em; }
  .l-contentArea .block-mefibs .views-widget-sort-by div {
    display: block !important; }

.l-contentArea .block-mefibs .views-widget-per-page div {
  display: block !important; }

.l-contentArea .block-mefibs label {
  font-weight: normal;
  float: left;
  margin-right: .6em; }

.l-contentArea .block-mefibs .views-submit-button {
  float: left;
  margin: -3px 0 0 1.5em; }
  .l-contentArea .block-mefibs .views-submit-button .form-submit {
    margin-top: 0; }

.l-sidebar .views-widget-sort-by,
.l-sidebar .views-widget-sort-order,
.l-sidebar .views-widget-per-page {
  margin-bottom: 0 !important;
  padding: 0 !important; }

.page-taxonomy-term .region-content .block p {
  font-style: normal;
  text-align: justify; }

.view-catalog .views-row {
  position: relative;
  float: left;
  overflow: hidden;
  width: 245px;
  height: 400px;
  margin: 0 2% 45px 2%;
  padding: 5px 0 20px 0;
  text-align: center;
  border: 1px solid #EDE6DD;
  border-radius: 3px; }
  @media (max-width: 1300px) {
    .view-catalog .views-row {
      margin: 0 1% 45px 1%; } }
  @media (max-width: 1200px) {
    .view-catalog .views-row {
      margin: 0 1% 45px 0; } }
  @media (max-width: 1100px) {
    .view-catalog .views-row {
      margin: 0 12px 45px 12px; } }
  @media (max-width: 960px) {
    .view-catalog .views-row {
      margin: 0 1% 45px 0; } }
  @media (max-width: 850px) {
    .view-catalog .views-row {
      margin: 0 20px 45px 20px; } }
  @media (max-width: 650px) {
    .view-catalog .views-row {
      margin: 0 5px 45px 5px; } }
  .view-catalog .views-row:hover {
    -webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
    -moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
    box-shadow: 0px 1px 6px 0px #C3B8AB; }
  .view-catalog .views-row .views-field-field-productpic {
    min-height: 175px; }
  .view-catalog .views-row .views-field-title a {
    font-size: 1.2em;
    display: inline-block;
    overflow: hidden;
    height: 46px;
    margin: 3px 0 10px 0;
    text-decoration: none;
    text-overflow: ellipsis;
    word-wrap: nowrap; }
    .view-catalog .views-row .views-field-title a:hover {
      text-decoration: underline; }
  .view-catalog .views-row .views-field-nothing {
    overflow: hidden;
    height: 75px; }
  .view-catalog .views-row .field-content {
    display: inline; }
  .view-catalog .views-row .views-field-field-oldprice {
    font-size: 1.2em;
    position: absolute;
    bottom: 77px;
    left: 0;
    width: 245px;
    min-height: 23px;
    margin-top: 7px;
    text-align: center; }
  .view-catalog .views-row .views-field-commerce-price {
    font-size: 1.5em;
    position: absolute;
    bottom: 53px;
    left: 0;
    width: 245px;
    text-align: center; }
  .view-catalog .views-row .views-field-ops {
    font-size: 2em;
    position: absolute;
    bottom: 10px;
    left: 0; }
    .view-catalog .views-row .views-field-ops a {
      padding-left: 15px;
      text-decoration: none; }
    .view-catalog .views-row .views-field-ops a:before {
      font-family: "Ionicons";
      content: " \f442";
      text-decoration: none; }
    .view-catalog .views-row .views-field-ops a.unflag-action:before {
      content: "\f443"; }
  .view-catalog .views-row .views-field-ops-1 {
    font-size: 2em;
    position: absolute;
    bottom: 10px;
    left: 30px; }
    .view-catalog .views-row .views-field-ops-1 a {
      margin-left: 15px;
      text-decoration: none; }
    .view-catalog .views-row .views-field-ops-1 a:before {
      font-family: "Ionicons";
      content: "\f453";
      text-decoration: none; }
    .view-catalog .views-row .views-field-ops-1 a.unflag-action:before {
      content: "\f454"; }
  .view-catalog .views-row .views-field-field-products input.form-submit {
    position: absolute;
    right: 20px;
    bottom: 15px; }

.view-catalog .view-header {
  font-style: italic;
  float: right;
  margin-top: -32px;
  margin-bottom: 10px; }
  @media (max-width: 1100px) {
    .view-catalog .view-header {
      float: left;
      margin-top: 0; } }

@media (max-width: 850px) {
  .view-catalog {
    width: 600px;
    margin: 0 auto; } }

@media (max-width: 650px) {
  .view-catalog {
    width: 540px;
    margin: 0 auto; } }

@media (max-width: 600px) {
  .view-catalog {
    width: 300px;
    margin: 0 auto; } }

.not-logged-in .view-catalog .views-row .toRegister {
  font-size: 2em;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-decoration: none; }
  .not-logged-in .view-catalog .views-row .toRegister:before {
    font-family: "Ionicons";
    content: "\f442";
    text-decoration: none; }

.form-item-quantity label {
  display: none; }

.form-item-quantity .commerce-quantity-plusminus-link a {
  text-decoration: none;
  color: #fff;
  background: #c66a4f; }

.form-item-quantity input.form-text {
  width: 30px;
  text-align: center; }

/* Блок сравнения */
#block-views-flag-compare-block-1 h2 {
  font-size: 1.2em;
  font-weight: normal; }

.region-highlighted .view-flag-compare {
  overflow: hidden;
  width: 95%;
  margin-bottom: 20px;
  padding: 10px 10px 0 10px;
  opacity: .85;
  border: 1px solid #c66a4f; }
  .region-highlighted .view-flag-compare .views-row {
    float: left;
    width: 25%;
    min-width: 100px;
    min-height: 100px;
    text-align: center;
    background: url("../images/blankflag.jpg") no-repeat top center; }
    .region-highlighted .view-flag-compare .views-row .views-field-title {
      width: 100px;
      margin: 0 auto; }
      .region-highlighted .view-flag-compare .views-row .views-field-title a {
        text-decoration: none; }
  .region-highlighted .view-flag-compare .view-footer {
    clear: both;
    width: 100%;
    padding-top: 5px;
    text-align: right; }

/* Карточка товара */
.node-type-product-display article.node-product-display {
  position: relative; }

.node-type-product-display h1.title {
  font-weight: normal;
  text-transform: uppercase; }

.node-type-product-display .field-name-field-productpic {
  float: left;
  overflow: hidden;
  width: 492px;
  margin-right: 10px;
  text-align: center; }
  @media (max-width: 930px) {
    .node-type-product-display .field-name-field-productpic {
      float: none;
      width: 500px;
      margin: 0 auto; } }

.node-type-product-display .group-params {
  line-height: 1.5;
  float: left;
  width: 600px; }
  @media (max-width: 1300px) {
    .node-type-product-display .group-params {
      width: 500px; } }
  @media (max-width: 1200px) {
    .node-type-product-display .group-params {
      width: 400px; } }
  @media (max-width: 1070px) {
    .node-type-product-display .group-params {
      width: 350px; } }
  @media (max-width: 1000px) {
    .node-type-product-display .group-params {
      width: 100%; } }

.node-type-product-display .commerce-product-field-field-oldprice,
.node-type-product-display .field-name-field-oldprice {
  font-size: 1.8em;
  float: left;
  padding-top: 5px;
  text-decoration: line-through;
  color: #666; }

.node-type-product-display .commerce-product-field-commerce-price,
.node-type-product-display .field-name-commerce-price {
  font-size: 2.3em;
  float: left;
  margin-left: 10px;
  color: #c66a4f; }

.node-type-product-display .field-name-field-products {
  overflow: hidden;
  width: 100%; }
  .node-type-product-display .field-name-field-products .form-item-quantity {
    float: left; }
  .node-type-product-display .field-name-field-products input.form-submit {
    float: left;
    margin: 12px; }

.node-type-product-display .field-name-field-producttype {
  display: none;
  padding-top: 10px; }

.node-type-product-display .flag-comparelist {
  font-size: 1.6em;
  position: absolute;
  top: 0;
  right: 3px; }
  .node-type-product-display .flag-comparelist a {
    text-decoration: none; }
  .node-type-product-display .flag-comparelist a:before {
    font-family: "Ionicons";
    content: "\f453";
    text-decoration: none; }
  .node-type-product-display .flag-comparelist a.unflag-action:before {
    content: "\f454"; }

.node-type-product-display .flag-mywishlist {
  font-size: 1.6em;
  position: absolute;
  top: 0;
  right: 21px; }
  .node-type-product-display .flag-mywishlist a {
    text-decoration: none; }
  .node-type-product-display .flag-mywishlist a:before {
    font-family: "Ionicons";
    content: " \f442";
    text-decoration: none; }
  .node-type-product-display .flag-mywishlist a.unflag-action:before {
    content: "\f443"; }

.node-type-product-display .field-name-body {
  float: none;
  clear: both;
  width: 100%;
  padding-top: 15px; }

/* Заказ в 1 клик */
.region-content {
  position: relative; }
  .region-content #block-block-6 {
    position: absolute;
    top: 60px;
    left: 750px; }
    @media (max-width: 1000px) {
      .region-content #block-block-6 {
        top: 465px;
        left: 250px; } }
    .region-content #block-block-6 .fastorder {
      display: block;
      padding: 5px 15px;
      text-decoration: none;
      color: #fff;
      background: #bd4131; }

/* Сопутствующие товары */
.view-related {
  clear: both;
  width: 100%; }
  .view-related:before {
    font-size: 1.5em;
    font-weight: bold;
    clear: both;
    content: "Сопутствующие товары";
    color: #c66a4f; }
  .view-related .view-content {
    padding-top: 0.83em; }

/* Похожие товары */
.view-similar .views-row,
.view-related .views-row,
#block-views-recently-read-block-1 .views-row {
  position: relative;
  float: left;
  overflow: hidden;
  width: 245px;
  margin: 0 2% 45px 2%;
  padding: 5px 0 20px 0;
  text-align: center;
  border: 1px solid #EDE6DD;
  border-radius: 3px; }
  @media (max-width: 1300px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 1% 45px 1%; } }
  @media (max-width: 1200px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 1% 45px 0; } }
  @media (max-width: 1100px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 12px 45px 12px; } }
  @media (max-width: 960px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 1% 45px 0; } }
  @media (max-width: 850px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 20px 45px 20px; } }
  @media (max-width: 650px) {
    .view-similar .views-row,
    .view-related .views-row,
    #block-views-recently-read-block-1 .views-row {
      margin: 0 5px 45px 5px; } }
  .view-similar .views-row:hover,
  .view-related .views-row:hover,
  #block-views-recently-read-block-1 .views-row:hover {
    -webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
    -moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
    box-shadow: 0px 1px 6px 0px #C3B8AB; }
  .view-similar .views-row .views-field-title a,
  .view-related .views-row .views-field-title a,
  #block-views-recently-read-block-1 .views-row .views-field-title a {
    font-size: 1.2em;
    display: inline-block;
    overflow: hidden;
    height: 46px;
    margin: 3px 0 10px 0;
    text-decoration: none;
    text-overflow: ellipsis;
    word-wrap: nowrap; }
    .view-similar .views-row .views-field-title a:hover,
    .view-related .views-row .views-field-title a:hover,
    #block-views-recently-read-block-1 .views-row .views-field-title a:hover {
      text-decoration: underline; }
  .view-similar .views-row .views-field-field-oldprice,
  .view-related .views-row .views-field-field-oldprice,
  #block-views-recently-read-block-1 .views-row .views-field-field-oldprice {
    font-size: 1.2em;
    min-height: 23px;
    margin-top: 7px;
    text-align: center; }
  .view-similar .views-row .views-field-commerce-price,
  .view-related .views-row .views-field-commerce-price,
  #block-views-recently-read-block-1 .views-row .views-field-commerce-price {
    font-size: 1.5em;
    text-align: center; }

#block-views-recently-read-block-1 {
  clear: both;
  width: 100%; }

/* Страница корзины */
.view-commerce-cart-form .views-field-edit-quantity {
  text-align: center; }
  .view-commerce-cart-form .views-field-edit-quantity .commerce-quantity-plusminus-link a {
    text-decoration: none;
    color: #fff;
    background: #c66a4f; }
  .view-commerce-cart-form .views-field-edit-quantity input.form-text {
    width: 30px;
    text-align: center; }

.view-commerce-cart-form .selectPackage {
  font-size: 1.3em;
  margin: 5px;
  text-decoration: none; }
  .view-commerce-cart-form .selectPackage:hover {
    text-decoration: underline; }

.view-commerce-cart-form .line-item-summary {
  font-size: 1.6em;
  color: #c66a4f; }
  .view-commerce-cart-form .line-item-summary .line-item-total-label {
    font-weight: normal;
    color: #666; }

.view-commerce-cart-form .commerce-line-item-actions input:first-child {
  margin-right: 15px; }

/* Оформление заказа */
.page-checkout fieldset {
  margin-bottom: 30px; }
  .page-checkout fieldset legend {
    font-size: 1.2em;
    color: #c66a4f; }

.page-checkout .field-name-commerce-order-total {
  font-size: 1.3em;
  margin: 5px 0px;
  color: #c66a4f; }
  .page-checkout .field-name-commerce-order-total .component-title {
    color: #666; }
  .page-checkout .field-name-commerce-order-total .component-total,
  .page-checkout .field-name-commerce-order-total .component-title {
    font-weight: normal;
    line-height: 1.65; }

.page-checkout .checkout-buttons .button-operator {
  margin: 0 5px; }

.page-checkout-review .checkout-help {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #c66a4f; }

.page-checkout-review table.checkout-review {
  width: 100%; }
  .page-checkout-review table.checkout-review td {
    padding: 5px; }

.page-checkout-review .pane-title td {
  font-size: 1.2em;
  font-weight: 400 !important;
  line-height: 2.5;
  color: #c66a4f; }

.page-checkout-review .field-name-commerce-order-total {
  font-style: 1.1em; }

.page-checkout-review fieldset.commerce_payment {
  margin: 10px 0; }

/* Статьи (полезная информация) */
.view-usefulinfo .views-row {
  clear: both;
  overflow: hidden;
  padding: 15px 0;
  border-bottom: 1px solid #c66a4f; }
  .view-usefulinfo .views-row:last-child {
    border-bottom: 0; }
  .view-usefulinfo .views-row .views-field-title a {
    font-size: 1.4em;
    margin-bottom: 10px;
    text-decoration: none; }
  .view-usefulinfo .views-row .views-field-view-node {
    clear: both; }
  .view-usefulinfo .views-row .views-field-field-articleimg {
    float: left;
    overflow: hidden;
    margin: 20px; }

.field-name-field-articleimg {
  float: left;
  overflow: hidden;
  margin: 20px; }

/* Адресная книга */
.view-commerce-addressbook-defaults .views-row, .view-commerce-addressbook .views-row {
  margin: 10px;
  padding: 10px;
  border-bottom: 1px dotted #c66a4f; }
  .view-commerce-addressbook-defaults .views-row .field-label, .view-commerce-addressbook .views-row .field-label {
    font-weight: 500;
    float: left; }
  .view-commerce-addressbook-defaults .views-row .field-name-field-giftcard,
  .view-commerce-addressbook-defaults .views-row .field-name-field-giftcardamount,
  .view-commerce-addressbook-defaults .views-row .field-name-field-oferta,
  .view-commerce-addressbook-defaults .views-row .field-name-field-comment, .view-commerce-addressbook .views-row .field-name-field-giftcard,
  .view-commerce-addressbook .views-row .field-name-field-giftcardamount,
  .view-commerce-addressbook .views-row .field-name-field-oferta,
  .view-commerce-addressbook .views-row .field-name-field-comment {
    display: none; }

.view-commerce-addressbook-defaults .views-row-last, .view-commerce-addressbook .views-row-last {
  border-bottom: 0; }

.view-commerce-addressbook-defaults {
  width: 500px;
  padding: 20px;
  border-bottom: 0;
  background: #ececec; }
  .view-commerce-addressbook-defaults .view-header {
    color: #c66a4f; }

.view-commerce-addressbook {
  display: none; }

/* Search results */
.page-search .node-product-display .field-name-field-productpic .field-item {
  display: none; }
  .page-search .node-product-display .field-name-field-productpic .field-item:first-child {
    display: block;
    float: left;
    margin-right: 10px; }

.page-search .node-product-display .field-name-field-oldprice {
  font-size: 1.2em;
  text-decoration: line-through;
  color: #666; }

.page-search .node-product-display .field-name-commerce-price {
  font-size: 1.2em;
  color: #c66a4f; }

/* Login when anonymous checkout */
.not-logged-in.page-checkout .login_pane:before {
  content: "Вы можете войти или зарегистрироваться. Для оформления заказа как анонимный пользователь - просто нажмите 'Продолжить оформление'"; }

/* Footer */
.footer-column {
  width: 23%; }

.footer-column-2 {
  width: 46%; }

.footer-column,
.footer-column-2 {
  float: left;
  margin-right: 2%; }
  .footer-column h3,
  .footer-column-2 h3 {
    font-size: 1.3em;
    margin: 0px;
    margin-bottom: 30px;
    padding: 0;
    text-transform: uppercase;
    color: #c66a4f; }
  .footer-column li,
  .footer-column-2 li {
    list-style: square; }
    .footer-column li a,
    .footer-column-2 li a {
      text-decoration: none;
      color: #666; }
      .footer-column li a:hover, .footer-column li a.is-active,
      .footer-column-2 li a:hover,
      .footer-column-2 li a.is-active {
        text-decoration: underline;
        color: #000; }
  .footer-column:nth-child(1),
  .footer-column-2:nth-child(1) {
    text-align: center;
    color: #666; }
    .footer-column:nth-child(1) .logo,
    .footer-column-2:nth-child(1) .logo {
      margin: 0 0 30px 0; }
  @media (max-width: 750px) {
    .footer-column,
    .footer-column-2 {
      float: none;
      clear: both;
      overflow: hidden;
      width: 300px;
      margin: 30px auto 30px auto;
      text-align: center; }
      .footer-column:first-child,
      .footer-column-2:first-child {
        overflow: visible; } }

.footer-column-2 h3 {
  text-align: center; }

.footer-column-2 ul {
  display: block;
  float: left;
  margin-top: 0; }
  .footer-column-2 ul:nth-child(3) {
    margin-left: 15%; }
    @media (max-width: 1300px) {
      .footer-column-2 ul:nth-child(3) {
        margin-left: 0; } }

.copyrightNendo {
  line-height: 34px;
  float: right;
  color: #666; }
  .copyrightNendo a {
    font-weight: bold;
    text-decoration: none;
    color: #c66a4f; }

.totop {
  font-size: 1.1em;
  position: fixed;
  right: 2em;
  bottom: 2em;
  display: none;
  padding: 1em;
  text-decoration: none;
  background-color: rgba(235, 235, 235, 0.8); }
  .totop:hover {
    background-color: rgba(135, 135, 135, 0.5); }

.is-red {
  color: #bd4131; }

.is-black {
  color: #000; }

.is-gray {
  color: #666; }

.is-terrakt {
  color: #c66a4f; }

.is-olive {
  color: #999540; }

.view-content table.views-table {
  width: 100%; }
  .view-content table.views-table th {
    padding: 10px; }
    .view-content table.views-table th a {
      text-decoration: none; }
  .view-content table.views-table td {
    padding: 10px;
    background: none; }
    .view-content table.views-table td a {
      text-decoration: none; }

.view-flag-bookmarks-tab .views-field-title {
  max-width: 150px; }

.view-flag-bookmarks-tab td.views-field-ops {
  font-size: 2em; }
  .view-flag-bookmarks-tab td.views-field-ops a {
    padding-left: 15px;
    text-decoration: none; }
  .view-flag-bookmarks-tab td.views-field-ops a:before {
    font-family: "Ionicons";
    content: " \f442";
    text-decoration: none; }
  .view-flag-bookmarks-tab td.views-field-ops a.unflag-action:before {
    content: "\f405"; }

.view-flag-compare th {
  width: 100px; }

.view-flag-compare td {
  text-align: center; }

.view-flag-compare .views-field-title {
  max-width: 100px; }

.view-flag-compare .flag-comparelist {
  font-size: 2em; }
  .view-flag-compare .flag-comparelist a {
    padding-left: 15px;
    text-decoration: none; }
  .view-flag-compare .flag-comparelist a:before {
    font-family: "Ionicons";
    content: " \f442";
    text-decoration: none; }
  .view-flag-compare .flag-comparelist a.unflag-action:before {
    content: "\f405"; }
