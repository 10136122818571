/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
@import "partials/iconfont.scss";

/*
* Custom
*/

@import "variables.scss";

body {
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	position: relative; 
	min-width: 500px;
	max-width: 1400px;
	height: 100%;
	margin: 0 auto;
	color: $color;
	background: $bg;
}

a {
	color: $terrakt;
	&:hover {text-decoration: none;}
}

p {
}

input.form-submit {
	padding: 5px 7px;
	color: $bg;
	border:none;
	background: $terrakt;
}

h1 {
	font-size: 1.7em;
	text-align: center;
	color: $terrakt;
}

h2 {
	font-size: 1.5em;
	color: $terrakt;
}

@import "layot.scss";
@import "module.scss";
@import "state.scss";
@import "drupal.scss";

@import "media.scss";