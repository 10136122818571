@import "variables.scss";


%l-layout {
	overflow: hidden;
	width: 94%;
	margin: 0 auto;
}

.l-header {
	position: relative;
	clear: both;
	overflow: visible;
	margin: 0 auto 90px auto;

	@extend %l-layout;
	@media (max-width: 1170px) {
		margin: 0 auto;
	}
}

.l-slider,
.block-nivo-slider {
	font-size: 1.5em;
	clear: both;
	width: 100%;
	max-width: 800px;
	max-height: 400px;
	margin: 0 auto;
	.nivo-caption {
		width: 90%;
		margin: 10px 5% 25px 5%;
		padding: 25px 20px;
		opacity: .8;
		background: #666;
	}
}

.l-content {
	margin: 30px auto;

	@extend %l-layout;
	.l-sidebar {
		float: left;
		width: 29%;
		min-width: 300px;
		margin-right: 1%;
		@media (max-width: 1200px) {margin-right: 0;}
		@media (max-width: 960px) {
			width: 100%;
			margin-right: 0;
		}
	}
	.l-contentArea {
		float: left;
		width: 70%;
		@media (max-width: 1100px) {width: 550px;margin-left: 30px;}
		@media (max-width: 960px) {
			width: 100%;
		}
		&.nosidebar {
			width: 100%;
			margin-left: 0;
		}
	}
}



.l-footer {
	padding-top: 50px;
	color: $gray;
	border-top: 1px solid $lightgray;
	border-bottom: 1px solid $lightgray;

	@extend %l-layout;
	.logo {
		font-size: 1.8em;
		line-height: 1.5em;
		@media (max-width: 1350px) {font-size: 1.6em;line-height: 1em;}
		@media (max-width: 1000px) {font-size: 1.5em;line-height: 1em;}
	}
	i {
		font-size: 16px;
		margin-right: 10px;
	}
}

.l-bottom {
	padding: 10px 0;

	@extend %l-layout;
	img {
		float: left;
		margin: 0 5px;
	}

	p {
		float: left;
		padding: 0;
		font-size:1.1em;
		margin: 6px 10px 0 0;
		color: $terrakt;
	}
}