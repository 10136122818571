@import "variables.scss";

.view-content table.views-table {
	width: 100%;
	th {
		padding: 10px;
		a {
			text-decoration: none;
		}
	}
	td {
		padding: 10px;
		background: none;
		a {
			text-decoration: none;
		}
	}
}

.view-flag-bookmarks-tab {
	.views-field-title {
		max-width: 150px;
	}
	td.views-field-ops {
		font-size: 2em;
			a {
				padding-left: 15px;
				text-decoration: none;
			}
			a:before {
				font-family: "Ionicons";
				content: " \f442";
				text-decoration: none;
			}
			a.unflag-action:before {
				content: "\f405";
			}
	}
}

.view-flag-compare {

	th {
		width: 100px;
	}

	td {
		text-align: center;
	}

	.views-field-title {
		max-width: 100px;
	}

	.flag-comparelist {
		font-size: 2em;
			a {
				padding-left: 15px;
				text-decoration: none;
			}
			a:before {
				font-family: "Ionicons";
				content: " \f442";
				text-decoration: none;
			}
			a.unflag-action:before {
				content: "\f405";
			}
	}
}