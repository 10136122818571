@import "variables.scss";

.headerTop {
	font-size: 1em;
	line-height: 30px;
	overflow: hidden;
	color: $gray;
	border-bottom: 1px solid $lightgray;
	.headerTop-left {
		font-size: 1.3em;
		position: relative;
		float: left;
		min-width: 174px;
		padding: 10px 0;
		small {
			font-size:.6em;
			position: absolute;
			top: 28px;
			left:21px;
			color: #999;
		}
	}
	.headerTop-right {
		float: right;
		padding: 10px 0;
		color: $terrakt;
		.userName a {
			position: relative;
			display: block;
			overflow: hidden;
			max-width: 100px;
			text-overflow: ellipsis;
			word-wrap: nowrap;
		}
		a {
			text-decoration: none;
			&:hover {text-decoration: underline;}
		}
	}
	i {
		font-size: 1.5em;
		display: block;
		float: left;
		margin: 0 3px 0 3px;
	}
	p {
		float: left;
		margin: 0 4px;
		padding: 0;
	}
	@media (max-width: 880px) { 
		.headerTop-right {
			p {
				display: none;
			}
			i {
				font-size: 2em;
				margin-right: .5em;
				color: $terrakt;
			}
		}
	}
	@media (max-width: 750px) {
		.headerTop-left {
			float: none;
			clear: both;
			overflow: hidden;
			width: 200px;
			margin: 0 auto;
		}

		.headerTop-right {
			float: none;
			clear: both;
			width: 460px;
			margin: 0;
			padding-top: 50px;
			p {
				display: inline;
			}
			i {
				font-size: 1.5em;
				margin-right: 0;
			}
		}
	}
}

.callback {
	display: block;
	float: left;
	overflow: hidden;
	margin: 5px 0 0 20px;
	padding: 4px 8px;
	color: #fff;
	background: $terrakt;
	@media (max-width: 700px) {
		float: none;
		clear: both;
		overflow: hidden;
		width: 232px;
		margin: 10px auto 0 auto;
	}
	a {
		text-decoration: none;
		color: #fff;
		&:hover {text-decoration: underline;}
	}
}

.searchBlock {
	position: absolute;
	top: 12px;
	left:470px;
	margin-left: 10px;
	input {
		width: 200px;
		padding: 3px 30px 3px 6px;
		border: 1px solid $gray;
	}
	i {
		position: absolute;
		top: 1px;
		right: 5px;
		color: $black;
	}
	.searchSubmit {
		position: absolute;
		top: 1px;
		right: 1px;
		width: 30px;
		height: 27px;
		margin: 0;
		padding: 0;
		border: 0;
		background: none;
	}
	@media (max-width: 1200px) {
		left: 455px; 
		margin: 0;
		input {
			width: 180px;
		}
	}
	@media (max-width: 1130px) { 
		left: 470px;
		input {
			width: 100px;
		}
	}
	@media (max-width: 700px) {
		top: 110px;
		left: 5%;
		width: 90%;
		input {
			width: 90%;
			padding: 3px 5% 3px 5%;
		}
	}
}

.headerMenu {
	position: relative;
	padding: 35px 0;
	@media (max-width: 1170px) {
		padding: 35px 0 0 0;
	}
}

.logo {
	font-size: 2em;
	font-weight: bold;
	position: relative;
	z-index: 2;
	float: left;
	padding-left: 65px;
	text-decoration: none;
	text-transform: uppercase;
	color: $terrakt;
	img {
    position: absolute;
    z-index: 1;
    top: -18px;
    left: -5px;
    width: 86px;
    height: 60px;
  }
	.site-descr {
		font-size: 0.40em;
		font-weight: normal;
		width: 270px;
		margin: 12px 0 0 -40px;
		padding: 0;
		text-align: center;
		text-transform: none;
		color: $black;
	}
}

.mainMenu {
	display: block;
	float: left;
	overflow: hidden;
	margin: 0 0 0 4%;
	padding: 0;
	list-style: none;
	li {
		float: left;
		a {
			font-size: 1.1em;
			font-weight: bold;
			line-height: 65px;
			display: block;
			float: left;
			margin: 0 10px;
			text-decoration: none;
			text-transform: uppercase;
			color: $gray;
			&:hover,
			&.is-active {
				color: $terrakt;
			}
		}
	}
	@media (max-width: 1370px) {
		margin: 0 0 0 1%;
		li {
			a {
				font-size: 1em;
			}
		}
	}
	@media (max-width: 1270px) {
		li {
			a {
				font-size: .9em;
				margin: 0 5px;
			}
		}
	}
	@media (max-width: 1230px) {
		clear: both;
		width: 100%;
		margin: 0 auto;
		margin-top: 20px;
		text-align: center;
		border-top: 1px solid $gray;
		li {
			a {
				font-size: 1em;
				line-height: 50px;
			}
		}
	}
}

.cart {
	line-height: 35px;
	position: relative;
	float: right;
	margin: -20px 3% 0 1%;
	a {
		text-decoration: none;
		&:hover {opacity: .7;}
	}
	i {
		font-size: 55px;
		margin-left: 18px;
		color: $gray;
	}
	.cart-label {
		line-height: 25px;
		position: absolute;
		top:-5px;
		right:18px;
		display: block;
		width: 25px;
		height: 25px;
		text-align: center;
		color: #fff;
		border-radius: 100%;
		background: $red;
	}
	.cart-description {
		line-height: 1;
		clear: both;
		text-align: center;
	}
	@media (max-width: 1400px) {
		margin: 0 0 0 1%;
		i {
			font-size: 40px;
		}
		.cart-label {
			right: 28px;
		}
	}
	@media (max-width: 1300px) {
		position: absolute;
		top: 30px;
		right: 5%;
	}
}

.ajax-shopping-cart-wrapper {
	position: absolute;
	z-index: 10;
	top: 115px;
	right: 30px;
	display: none;
	width: 400px;
	padding: 5px;
	border: 1px solid $terrakt;
	background: #fff;
	.ajax-shopping-cart-table {
		th {
			font-size: .7em;
			font-weight: normal;
			text-align: center;
		}
		td {
			padding: 2px;
			text-align: center;
			&.name {
				text-align: left;
			}
			&.remove-from-cart {
				font-size: .8em;
			}
		}
	}
	.ajax-shopping-cart-more-info {
		font-size:1.1em;
		padding-top: 15px;
		color: $terrakt;
	}
}

.catalogFront {
	overflow: hidden;
	width: 1050px;
	margin: 0 auto;
	text-align: center;
	@media (max-width: 1090px) {width: 654px;}
	@media (max-width: 654px) {width: 436px;}
	.catalogFront-item {
		position: relative;
		float: left;
		margin: 4px;
		border: 1px solid #EDE6DD;
		border-radius: 3px;
		-webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
		-moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
		box-shadow: 0px 1px 6px 0px #C3B8AB;
		p {
			font-size: 1.2em;
			position: absolute;
			top: 147px;
			left: 0;
			width: 200px;
			height: 30px;
			padding-top: 7px;
			text-align: center;
			text-transform: uppercase;
			opacity: .8;
			color: #fff;
			background: #666;
		}
		&:hover {
			opacity: .8;
			background: #000;
		}
		&:nth-child(9) p,
		&:nth-child(12) p,
		&:nth-child(15) p {
			top: 125px;
			height: 53px
		}
	}
}

.features {
	overflow: hidden;
	width: auto;
	margin: 20px auto;
	.features-inner {
		overflow: hidden;
		width: 1200px;
		margin: 0 auto;
		border-radius: 10px;
		background: $terrakt;
		.features-inner-feature {
			float: left;
			width: 249px;
			height: 65px;
			padding: 0 0 0 50px;
			text-decoration: none;
			color: #fff;
			border-right: 1px solid white;
			&:last-child {
				border-right: 0;
			}
			&:hover,
			&.is-active {
				background: $red;
			}
			i {
				font-size: 3.7em;
				display: block;
				float: left;
				margin: -5px 20px 0 0;
				&.icon-wallet-money {
					font-size: 3.4em;
					margin: 7px 20px 0 0;
				}
			}
			p {
				font-size: 1.3em;
				margin: 9px;
				padding: 0;
				text-align: center;
			}
		}
		@media (max-width: 1300px) {
			width: 600px;
			.features-inner-feature:nth-child(2),
			.features-inner-feature:nth-child(4) {
				border-right: none;
			}
		}
		@media (max-width: 700px) {
			width: 300px;
			.features-inner-feature {
				border-right: none;
				border-bottom: 1px solid white;
				&:last-child {border-bottom: none;}
			}
		}
	}
}

#breadcrumb a {
	font-size: .9em;
	text-decoration: none;
	color: $gray;
	&:hover {text-decoration: underline;color:#000;}
}

/* Блок "показать по", "сортировать по" */
#block-block-5 {
	clear: both;
	overflow: hidden;
	width: 100%;
	p {
		float: left;
		margin-right: 10px;
		&:nth-child(2) {margin-right: 35px;}
	}
	input.form-submit {
		float: left;
		margin: 10px 0 0 10px;
	}
}


/* Блоки меню каталога */
#block-menu-menu-catalog,
#block-multiblock-1 {
	position: relative;
	width: 300px;
	border: 0;
	h2 {
		font-size: 1.3em;
		margin-top:0;
		padding: 10px 0;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		background: $terrakt;
		&:after {
		content: " >>";
		}
	}
	.content {
		position: absolute;
		z-index: 3;
		top: 44px;
		left: 0px;
		width: 300px;
		height: auto;
		padding: 0;
		border: 1px solid $terrakt;
		background: #fff;
		ul {
			margin: 0;
			padding: 0;
			li {
				margin: 0;
				padding: 0;
				list-style: none;
				a {
					font-size: 1.1em;
					line-height: 44px;
					display: block;
					text-align: center;
					text-decoration: none;
					border-bottom: 1px solid $gray;
					&:hover,
					&.active {
						color: #fff;
						background: $terrakt;
					}
				}
				&:last-child a {
						border-bottom: none;
					}
			}
		}
	}
}



.region-sidebar {
	.block {
		width: 300px;
		margin: 0 auto 20px auto;
		border: 1px solid $terrakt;
		@media (max-width: 960px) { width: 96%;}
		h2 {
			font-size: 1.3em;
			margin-top:0;
			padding: 10px 0;
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			background: $terrakt;
		}
		.content {
			padding: 5px 15px;
		}
	}
	/* Фильтры */
	.views-exposed-widgets {
		.views-exposed-widget {
			width: 100%;
			margin-bottom: 20px;
			label {
				font-size: 1em;
				font-weight: normal;
				margin-bottom: 10px;
				text-transform: uppercase;
				color: $terrakt;
				&.option {
					margin-bottom: 0;
					text-transform: none;
					color: $color;
				}
			}
			select {
				min-width: 200px;
				padding: 5px;
				border: 1px solid $gray;
				background: none;
			}
			.views-widget {
				clear: both;
				width: 100%;
			}
			&.slider-filter-processed {
				.views-widget {
					div.form-type-textfield:nth-child(1) {
						input {
							float: left;
							width: 50px;
						}
					}
					div.form-type-textfield:nth-child(2) {
						label {
							display: none;
						}
						input {
							float: left;
							width: 50px;
							margin-left: 144px; 
						}
					}
				}
			}
			.ui-slider {
				width: 250px;
				margin-top: 45px;
			}
			&.views-submit-button,
			&.views-reset-button {
				width: auto;
				margin-bottom: 0;
			}
		}
	}
	#block-views-recently-read-block{
		@media (max-width: 960px) {display: none; }
	}
	.view-recently-read {
		overflow: hidden;
		.views-row {
			float: left;
			overflow: hidden;
			width: 100px;
			height: 140px;
			margin-bottom: 20px;
			list-style: none;
			text-align: center;
			.views-field-title {
				overflow: hidden;
				height: 39px;
			}
		}
	}
}

.views-field-field-oldprice {
	text-decoration:line-through;
	color: $gray;
}



.l-contentArea .block-mefibs {
	.views-exposed-form .views-exposed-widget {
		padding: 0;
	}
	.views-widget-sort-by {
		margin-right: 1em;
		div {
			display: block !important;
		}
	}
	.views-widget-per-page {
		div {
			display: block !important;
		}
	}
	label {
		font-weight: normal;
		float: left;
		margin-right: .6em;
	}
	.views-submit-button {
		float: left;
		margin: -3px 0 0 1.5em;
		.form-submit {
			margin-top: 0;
		}
	}
}

.l-sidebar {
	.views-widget-sort-by,
	.views-widget-sort-order,
	.views-widget-per-page {
		margin-bottom: 0 !important;
		padding: 0 !important;
	}
}

.page-taxonomy-term .region-content .block p {
	font-style: normal;
	text-align: justify;
}

.view-catalog {
	.views-row {
		position: relative;
		float: left;
		overflow: hidden;
		width: 245px;
		height: 400px;
		margin: 0 2% 45px 2%;
		padding: 5px 0 20px 0;
		text-align: center;
		border: 1px solid #EDE6DD;
		border-radius: 3px;
		@media (max-width: 1300px) {margin: 0 1% 45px 1%;}
		@media (max-width: 1200px) {margin: 0 1% 45px 0;}
		@media (max-width: 1100px) {margin: 0 12px 45px 12px;}
		@media (max-width: 960px) {margin: 0 1% 45px 0;}
		@media (max-width: 850px) {margin: 0 20px 45px 20px;}
		@media (max-width: 650px) {margin: 0 5px 45px 5px;}
		&:hover {
			-webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
			-moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
			box-shadow: 0px 1px 6px 0px #C3B8AB;
		}
		.views-field-field-productpic {
			min-height: 175px;
		}
		.views-field-title a {
			font-size: 1.2em;
			display: inline-block;
			overflow: hidden;
			height: 46px;
			margin: 3px 0 10px 0;
			text-decoration: none;
			text-overflow: ellipsis;
			word-wrap: nowrap;
			&:hover {text-decoration: underline;}
		}
		.views-field-nothing {
			overflow: hidden;
			height: 75px;
		}
		.field-content {
			display: inline;
		}
		.views-field-field-oldprice {
			font-size: 1.2em;
			position: absolute;
			bottom: 77px;
			left:0;
			width:245px;
			min-height: 23px;
			margin-top: 7px;
			text-align: center;
		}
		.views-field-commerce-price {
			font-size: 1.5em;
			position: absolute;
			bottom: 53px;
			left:0;
			width:245px;
			text-align: center;
		}
		.views-field-ops {
			font-size: 2em;
			position: absolute;
			bottom: 10px;
			left: 0;
			a {
				padding-left: 15px;
				text-decoration: none;
			}
			a:before {
				font-family: "Ionicons";
				content: " \f442";
				text-decoration: none;
			}
			a.unflag-action:before {
				content: "\f443";
			}
		}
		.views-field-ops-1 {
			font-size: 2em;
			position: absolute;
			bottom: 10px;
			left: 30px;
			a {
				margin-left: 15px;
				text-decoration: none;
			}
			a:before {
				font-family: "Ionicons";
				content:"\f453";
				text-decoration: none;
			}
			a.unflag-action:before {
				content:"\f454";
			}
		}
		.views-field-field-products {
			input.form-submit {
				position: absolute;
				right: 20px;
				bottom: 15px;
			}
		}
	}
	.view-header {
		font-style: italic;
		float: right;
		margin-top: -32px;
		margin-bottom: 10px;
		@media (max-width: 1100px) {
			float: left;
			margin-top: 0;
		}
	}
	@media (max-width: 850px) {width: 600px;margin: 0 auto;}
	@media (max-width: 650px) {width: 540px;margin: 0 auto;}
	@media (max-width: 600px) {width: 300px;margin: 0 auto;}
}

.not-logged-in .view-catalog .views-row .toRegister {
	font-size: 2em;
	position: absolute;
	bottom: 10px;
	left: 10px;
	text-decoration: none;
	&:before {
		font-family: "Ionicons";
		content:"\f442";
		text-decoration: none;
	}
}


.form-item-quantity {
	label {
		display: none;
	}
	.commerce-quantity-plusminus-link a {
		text-decoration: none;
		color: #fff;
		background: $terrakt;
	}
	input.form-text {
		width: 30px;
		text-align: center;
	}
}

/* Блок сравнения */

#block-views-flag-compare-block-1 h2 {
	font-size: 1.2em;
	font-weight: normal;
}

.region-highlighted .view-flag-compare {
	overflow: hidden;
	width: 95%;
	margin-bottom: 20px;
	padding: 10px 10px 0 10px;
	opacity: .85;
	border: 1px solid $terrakt;
	.views-row {
		float: left;
		width: 25%;
		min-width: 100px;
		min-height: 100px;
		text-align: center;
		background: url('../images/blankflag.jpg') no-repeat top center;
		.views-field-title {
			width: 100px;
			margin: 0 auto;
			a {text-decoration: none;}
		}
	}
	.view-footer {
		clear: both;
		width: 100%;
		padding-top: 5px;
		text-align: right;
	}
}

/* Карточка товара */

.node-type-product-display {
	article.node-product-display {position: relative;}
	h1.title {
		font-weight: normal;
		text-transform: uppercase;
	}
	.field-name-field-productpic {
		float: left;
		overflow: hidden;
		width: 492px;
		//height: 405px;
		margin-right: 10px;
		text-align: center;
		@media (max-width: 930px) {
			float:none;
			width: 500px;
			margin: 0 auto;
		}
	}
	.group-params {
		line-height: 1.5;
		float: left;
		width: 600px;
		@media (max-width: 1300px) {width: 500px;}
		@media (max-width: 1200px) {width: 400px;}
		@media (max-width: 1070px) {width: 350px;}
		@media (max-width: 1000px) {width: 100%;}
	}
	.commerce-product-field-field-oldprice,
	.field-name-field-oldprice {
		font-size: 1.8em;
		float: left;
		padding-top:5px;
		text-decoration: line-through;
		color: $gray;
	}
	.commerce-product-field-commerce-price,
	.field-name-commerce-price {
		font-size: 2.3em;
		float: left;
		margin-left: 10px;
		color: $terrakt;
	}
	.field-name-field-products {
		overflow: hidden;
		width: 100%;
		.form-item-quantity {
			float: left;
		}
		input.form-submit {
			float: left;
			margin: 12px;
		}
	}
	.field-name-field-producttype {
		display: none;
		padding-top: 10px;
	}
	.flag-comparelist {
		font-size: 1.6em;
		position: absolute;
		top:0; 
		right:3px;
		a {
			text-decoration: none;
		}
		a:before {
			font-family: "Ionicons";
			content:"\f453";
			text-decoration: none;
			}
		a.unflag-action:before {
			content:"\f454";
			}
	}
	.flag-mywishlist {
			font-size: 1.6em;
			position: absolute;
			top:0; 
			right:21px;
			a {
				text-decoration: none;
			}
			a:before {
				font-family: "Ionicons";
				content: " \f442";
				text-decoration: none;
			}
			a.unflag-action:before {
				content: "\f443";
			}
	}
	.field-name-body {
		float: none;
		clear: both;
		width: 100%;
		padding-top: 15px;
	}
}

/* Заказ в 1 клик */

.region-content {
	position: relative;
	#block-block-6 {
		position: absolute;
		top: 60px;
		left: 750px;
		@media (max-width: 1000px) {
			top: 465px;
			left: 250px;
		}
		.fastorder {
			display: block;
			padding: 5px 15px;
			text-decoration: none;
			color: #fff;
			background: $red;
		}
	}
}

/* Сопутствующие товары */

.view-related {
	clear: both;
	width: 100%;
	&:before {
		font-size: 1.5em;
		font-weight: bold;
		clear: both;
		content: "Сопутствующие товары";
		color: $terrakt;
	}
	.view-content {
		padding-top:0.83em;
	}
}



/* Похожие товары */

.view-similar,
.view-related,
#block-views-recently-read-block-1 {
	.views-row {
		position: relative;
		float: left;
		overflow: hidden;
		width: 245px;
		margin: 0 2% 45px 2%;
		padding: 5px 0 20px 0;
		text-align: center;
		border: 1px solid #EDE6DD;
		border-radius: 3px;
		@media (max-width: 1300px) {margin: 0 1% 45px 1%;}
		@media (max-width: 1200px) {margin: 0 1% 45px 0;}
		@media (max-width: 1100px) {margin: 0 12px 45px 12px;}
		@media (max-width: 960px) {margin: 0 1% 45px 0;}
		@media (max-width: 850px) {margin: 0 20px 45px 20px;}
		@media (max-width: 650px) {margin: 0 5px 45px 5px;}
		&:hover {
			-webkit-box-shadow: 0px 1px 6px 0px #C3B8AB;
			-moz-box-shadow: 0px 1px 6px 0px #C3B8AB;
			box-shadow: 0px 1px 6px 0px #C3B8AB;
		}
		.views-field-title a {
			font-size: 1.2em;
			display: inline-block;
			overflow: hidden;
			height: 46px;
			margin: 3px 0 10px 0;
			text-decoration: none;
			text-overflow: ellipsis;
			word-wrap: nowrap;
			&:hover {text-decoration: underline;}
		}
		.views-field-field-oldprice {
			font-size: 1.2em;
			min-height: 23px;
			margin-top: 7px;
			text-align: center;
		}
		.views-field-commerce-price {
			font-size: 1.5em;
			text-align: center;
		}
	}
}

#block-views-recently-read-block-1 {
	clear: both;
	width: 100%;
}


/* Страница корзины */

.view-commerce-cart-form {
	.views-field-edit-quantity {
		text-align: center;
		.commerce-quantity-plusminus-link a {
			text-decoration: none;
			color: #fff;
			background: $terrakt;
		}
		input.form-text {
			width: 30px;
			text-align: center;
		}
	}
	.selectPackage {
		font-size: 1.3em;
		margin: 5px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.line-item-summary {
		font-size: 1.6em;
		color: $terrakt;
		.line-item-total-label {
			font-weight: normal;
			color: $gray;
		}
	}
	.commerce-line-item-actions {
		input:first-child {
			margin-right: 15px;
		}
	}
}

/* Оформление заказа */

.page-checkout {
	fieldset {
		margin-bottom: 30px;
		legend {
			font-size: 1.2em;
			color: $terrakt;
		}
	}
	.field-name-commerce-order-total {
		font-size: 1.3em;
		margin: 5px 0px;
		color: $terrakt;
		.component-title {
			color: $gray;
		}
		.component-total,
		.component-title {
			font-weight: normal;
			line-height: 1.65;
		}
	}
	.checkout-buttons {
		.button-operator {
			margin: 0 5px;
		}
	}
}

.page-checkout-review {
	.checkout-help {
		font-size: 1.2em;
		margin-bottom: 30px;
		color: $terrakt;
	}
	table.checkout-review {
		width: 100%;
		td {
			padding: 5px;
		}
	}
	.pane-title td {
		font-size: 1.2em;
		font-weight: 400 !important;
		line-height: 2.5;
		color: #c66a4f;
	}
	.field-name-commerce-order-total {
		font-style: 1.1em;
	}
	fieldset.commerce_payment {
		margin: 10px 0;
	}
}

/* Статьи (полезная информация) */

.view-usefulinfo {
	.views-row {
		clear: both;
		overflow: hidden;
		padding: 15px 0;
		border-bottom: 1px solid $terrakt;
		&:last-child {
			border-bottom: 0;
		}
		.views-field-title a {
			font-size: 1.4em;
			margin-bottom: 10px;
			text-decoration: none;
		}
		.views-field-view-node {
			clear: both;
		}
		.views-field-field-articleimg {
			float: left;
			overflow: hidden;
			margin: 20px;
		}
	}
}

.field-name-field-articleimg {
	float: left;
	overflow: hidden;
	margin: 20px;
}


/* Адресная книга */

.view-commerce-addressbook-defaults, .view-commerce-addressbook {
	.views-row {
		margin: 10px;
		padding: 10px;
		border-bottom: 1px dotted $terrakt;
		.field-label {
			font-weight: 500;
			float: left;
		}
		.field-name-field-giftcard,
		.field-name-field-giftcardamount,
		.field-name-field-oferta,
		.field-name-field-comment {
			display: none;
		}
	}
	.views-row-last {
		border-bottom: 0;
	}
}

.view-commerce-addressbook-defaults {
	width: 500px;
	padding: 20px;
	border-bottom: 0;
	background: #ececec;
	.view-header {
		color: $terrakt;
	}
}

.view-commerce-addressbook  {
	display: none;
}

/* Search results */

.page-search {
	.node-product-display {
		.field-name-field-productpic {
			.field-item {
				display: none;
				&:first-child {
					display: block;
					float: left;
					margin-right: 10px;
				}
			}
		}
		.field-name-field-oldprice {
			font-size: 1.2em;
			text-decoration:line-through;
			color: $gray;
		}
		.field-name-commerce-price {
			font-size: 1.2em;
			color: $terrakt;
		}
	}
}

/* Login when anonymous checkout */

.not-logged-in.page-checkout {
	.login_pane {
		&:before {
			content: "Вы можете войти или зарегистрироваться. Для оформления заказа как анонимный пользователь - просто нажмите 'Продолжить оформление'";
		}
	}
}

/* Footer */

.footer-column {width: 23%;}
.footer-column-2 {width: 46%;}

.footer-column,
.footer-column-2 {
	float: left;
	margin-right: 2%;
	h3 {
		font-size: 1.3em;
		margin: 0px;
		margin-bottom: 30px;
		padding: 0;
		text-transform: uppercase;
		color: $terrakt;
	}
	li {
		list-style: square;
		a {
			text-decoration: none;
			color: $gray;
			&:hover,
			&.is-active {
				text-decoration: underline;
				color: $black;
			}
		}
	}
	&:nth-child(1) {
		text-align: center;
		color: $gray;
		.logo {
			margin:0 0 30px 0;
		}
	}
	@media (max-width: 750px) {
		float: none;
		clear: both;
		overflow: hidden;
		width: 300px;
		margin: 30px auto 30px auto;
		text-align: center;
		&:first-child {
			overflow: visible;
		}
	}
}

.footer-column-2 {
	h3 {
		text-align: center;
	}
	ul {
		display: block;
		float: left;
		margin-top: 0;
		&:nth-child(3) {
			margin-left: 15%;
			@media (max-width: 1300px) {margin-left: 0;}	
		}
	}
}

.copyrightNendo {
	line-height: 34px;
	float: right;
	color: $gray;
	a {
		font-weight: bold;
		text-decoration: none;
		color: $terrakt;
	}
}

.totop {
	font-size: 1.1em;
	position: fixed;
	right: 2em;
	bottom: 2em;
	display: none;
	padding: 1em;
	text-decoration: none;
	background-color: rgba(235, 235, 235, 0.80);
	&:hover {
		background-color: rgba(135, 135, 135, 0.50);
	}
}
